const getters = {
  getProductSpecification: (state, getter, rootState) => {
    return null !== rootState.CatalogProduct.productData.specifications ? rootState.CatalogProduct.productData.specifications : {};
  },

  getStartingPrice: (state, getter, rootState) => {
    if (!rootState.CatalogProduct.productData.hasOwnProperty('lt_price')) {
      return 0;
    }

    if (null === rootState.CatalogProduct.productData.lt_price) {
      return 0;
    }

    return rootState.CatalogProduct.productData.lt_price

  },

  getProductType: (state, getter, rootState) => {
    if (!rootState.CatalogProduct.productData.hasOwnProperty('product_type')) {
      return 'simple';
    }

    return rootState.CatalogProduct.productData.product_type

  }
};

const CatalogHelper = {
  namespaced: true,
  getters
};

export default CatalogHelper;
