document.addEventListener('DOMContentLoaded', function() {
    let uspInterval: number | undefined;
    let resizeTimeout: number | undefined;
    let lastWindowWidth = window.innerWidth;

    if (window.innerWidth <= 941) {
        selectRandomUSP();
        startUSPInterval();
    }

    window.addEventListener('resize', function() {
        if (resizeTimeout) {
            clearTimeout(resizeTimeout);
        }
        resizeTimeout = window.setTimeout(function() {
            const currentWindowWidth = window.innerWidth;

            if ((lastWindowWidth > 941 && currentWindowWidth <= 941) || (lastWindowWidth <= 941 && currentWindowWidth > 941)) {
                if (currentWindowWidth > 941) {
                    showAllUSPs();
                    stopUSPInterval(); 
                } else {
                    selectRandomUSP();
                    startUSPInterval(); 
                }
            }
            lastWindowWidth = currentWindowWidth;
        }, 200);
    });

    function selectRandomUSP() {
        const usps = Array.from(document.querySelectorAll('.header-usps ul li')) as HTMLElement[];
        if (usps.length > 0) {
            const randomIndex = Math.floor(Math.random() * usps.length);
            usps.forEach((usp, index) => {
                usp.style.display = index === randomIndex ? 'flex' : 'none';
            });
        }
    }

    function showAllUSPs() {
        const usps = Array.from(document.querySelectorAll('.header-usps ul li')) as HTMLElement[];
        usps.forEach(usp => usp.style.display = 'flex');
    }

    function startUSPInterval() {
        if (uspInterval) {
            clearInterval(uspInterval);
        }
        uspInterval = window.setInterval(() => {
            selectRandomUSP();
        }, 5000);
    }

    function stopUSPInterval() {
        if (uspInterval) {
            clearInterval(uspInterval);
            uspInterval = undefined;
        }
    }
});
