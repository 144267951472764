const initialState = () => {
  return {
    loadingState: false,
    showConfigurationSteps: false,
    configurationSteps: {}
  }
};

const state = initialState();

const mutations = {
  SET_CONFIGURATION_STEPS(state, payload) {
    state.configurationSteps = payload;
  },
  UPDATE_LOADING_STATE(state, payload) {
    state.loadingState = payload;
  },
  SET_SHOW_CONFIGURATION_STEPS(state, payload) {
    state.showConfigurationSteps = payload;
  }
};

const actions = {
  getConfigurationData({commit}, callbackUrl) {
    commit('UPDATE_LOADING_STATE', true);
    try {
      this.$solarClient.get(callbackUrl).then(({data}) => {
        commit('SET_CONFIGURATION_STEPS', data);
        if (!data['completed'] && !data.error) {
          commit('SET_SHOW_CONFIGURATION_STEPS', true);
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit('UPDATE_LOADING_STATE', false);
    }
  },
  createInitialConfigurationSteps({commit, dispatch, getters}, productSku) {
    const callbackUrl = `/api/logictrade/${productSku}`;
    dispatch('getConfigurationData', callbackUrl);
  }
};

const getters = {
  getLoadingState: state => {
    return state.loadingState;
  },
  getConfigurationSteps: state => {
    return state.configurationSteps;
  },
  getConfiguratorState: state => {
    return state.configurationSteps['completed'];
  },
  getConfiguratorGuid: state => {
    return state.configurationSteps['configurationGuid'] || '';
  },
  getShowConfigurationSteps: state => {
    return state.showConfigurationSteps;
  },
  getConfigurationPrice: state => {
    return {
      priceInclTax: state.configurationSteps.hasOwnProperty('summary') ? state.configurationSteps['summary']['Price'] : 0,
      totalPriceInclTax: state.configurationSteps.hasOwnProperty('summary') ? state.configurationSteps['summary']['ActionPrice'] : 0
    };
  }
};

const CatalogConfigurator = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

export default CatalogConfigurator;
