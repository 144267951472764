import {appSessionStorage} from "../../../shared/services";
const initialState = () => {
  return {
    cartTotals: appSessionStorage.getItem('cart-totals', {}),
    cartItems: appSessionStorage.getItem('cart-items', {}),
    cartItemsCount: 0,
    totalsAvailable: false,
    showCartPopup: false,
    showCartModal: false
  };
}

const getters = {
  cartItems: (state) => state.cartItems,
  cartTotals: (state) => state.cartTotals,
  totalsAvailable: (state) => state.totalsAvailable,
  showCartPopup: (state) => state.showCartPopup,
  showCartModal: (state) => state.showCartModal
};

const mutations = {
  SET_CART_TOTALS(state, payload) {
    appSessionStorage.setItem('cart-totals', payload);
    state.cartTotals = payload;
  },
  SET_CART_ITEMS(state, payload) {
    appSessionStorage.setItem('cart-items', payload);
    state.cartItems = payload;
  },
  SET_TOTALS_AVAILABLE(state, payload) {
    state.totalsAvailable = payload;
  },
  SET_SHOW_CART_POPUP(state, payload) {
    state.showCartPopup = payload;
  },
  SET_SHOW_CART_MODAL(state) {
    state.showCartModal = !state.showCartModal;
  }
};

const actions = {
  async fetchCartTotals({commit}) {
    try {
      commit('SET_TOTALS_AVAILABLE', false);
      const {data} = await this.$solarClient.get(`/api/checkout/totals/collect`);
      commit('SET_CART_TOTALS', data);
      commit('SET_CART_ITEMS', data['items']);
    } catch (err) {
      //
    } finally {
      commit('SET_TOTALS_AVAILABLE', true);
    }
  },

  async showMiniCart({commit}) {
    commit('SET_SHOW_CART_POPUP', true);
    setTimeout(() => {
      commit('SET_SHOW_CART_POPUP', false);
    }, 5000);
  },

  async closeMiniCart({commit}) {
    commit('SET_SHOW_CART_POPUP', false);
  },

  async showAddToCartModal({commit}) {
    commit('SET_SHOW_CART_MODAL');
  }

}

const state = initialState();

const CheckoutTotals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutTotals;
